@import '~antd/dist/antd.css';

* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  background: #4d4d4d;
  height: 80%;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.survey-card {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  -webkit-box-shadow: 10px 10px 40px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 40px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 40px -5px rgba(0, 0, 0, 0.75);
}
.survey-bg {
  background-color: aliceblue;
}

.survey-text {
  color: white;
}

#header-fixed {
  position: fixed;
  top: 0px;
  display: none;
  background-color: white;
}
